<template>
  <div>
    <b-row>
      <b-col
        v-if="licenseFeatures.length > 0"
        cols="12"
        md="6"
      >
        <b-card
          :title="`Licensed Features (${licenseFeatures.length})`"
        >
          <div class="table-responsive config-table-wrapper">
            <table class="table table-sm table-borderless">
              <thead>
                <tr>
                  <th>Feature</th>
                  <th>License Key</th>
                  <th>Mode</th>
                  <th>Expiration</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="feature in licenseFeatures"
                >
                  <td>{{ feature.feature }}</td>
                  <td>{{ feature.licenseKey }}</td>
                  <td>{{ feature.mode }}</td>
                  <td>{{ feature.expirationDate }}</td>
                </tr>

              </tbody>
            </table>
          </div>
        </b-card>
      </b-col>
      <b-col
        v-if="licenseCapacities.length > 0"
        cols="12"
        md="6"
      >
        <b-card :title="`Licensed Capacities (${licenseCapacities.length})`">
          <div class="table-responsive config-table-wrapper">
            <table class="table table-sm table-borderless">
              <thead>
                <tr>
                  <th>Feature</th>
                  <th>Expiration</th>
                  <th>Capacity</th>
                  <th>Shelf Model</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="licenseCapacity in licenseCapacities"
                >
                  <td>{{ licenseCapacity.feature }}</td>
                  <td>{{ licenseCapacity.expirationDate }}</td>
                  <td>{{ licenseCapacity.capacity }}</td>
                  <td>{{ licenseCapacity.shelfModel }}</td>
                </tr>

              </tbody>
            </table>
          </div>
        </b-card>
      </b-col>
      <b-col
        v-if="mtreeEntries.length > 0"
        cols="12"
        md="12"
      >
        <b-card :title="`MTree Entries (${mtreeEntries.length})`">
          <div class="table-responsive config-table-wrapper">
            <table class="table table-sm table-borderless">
              <thead>
                <tr>
                  <th>MTree</th>
                  <th>Status</th>
                  <th>Snap</th>
                  <th>Snap min. expiration</th>
                  <th>Snap max. expiration</th>
                  <th>Storage unit</th>
                  <th>Storage unit user</th>
                  <th>Storage unit status</th>
                  <th>Storage unit PreComp (GB)</th>
                  <th>Storage unit phsyical size</th>
                  <th>PreComp (GB)</th>
                  <th>Quota hard limit (Mib)</th>
                  <th>Quota soft limit (Mib)</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="mtreeEntry in mtreeEntries"
                >
                  <td>{{ mtreeEntry.name }}</td>
                  <td>{{ mtreeEntry.mtreeStatus }}</td>
                  <td>{{ mtreeEntry.snapExpired }} expired / {{ mtreeEntry.snapNotExpired }} not expired</td>
                  <td>{{ mtreeEntry.snapMinExptime }}</td>
                  <td>{{ mtreeEntry.snapMaxExptime }}</td>
                  <td>{{ mtreeEntry.storageUnit }}</td>
                  <td>{{ mtreeEntry.storageUnitUser }}</td>
                  <td>{{ mtreeEntry.storageUnitStatus }}</td>
                  <td>{{ mtreeEntry.storageUnitPrecompGb }}</td>
                  <td>{{ mtreeEntry.storageUnitPhysicalSize }}</td>
                  <td>{{ mtreeEntry.preComp }}</td>
                  <td>{{ mtreeEntry.quotaHardLimitMib }}</td>
                  <td>{{ mtreeEntry.quoteSoftLimitMib }}</td>
                </tr>

              </tbody>
            </table>
          </div>
        </b-card>
      </b-col>
      <b-col
        v-if="mtreeCompressions.length > 0"
        cols="12"
        md="12"
      >
        <b-card :title="`MTree show compression`">
          <div class="table-responsive config-table-wrapper">
            <table class="table table-sm table-borderless">
              <thead>
                <tr>
                  <th>MTree</th>
                  <th>Pre (GiB) 24 hrs</th>
                  <th>Post (GiB) 24 hrs</th>
                  <th>Global factor 24 hrs</th>
                  <th>Local factor 24 hrs</th>
                  <th>Total factor 24hrs</th>
                  <th>Pre (GiB) 7 days</th>
                  <th>Post (GiB) 7 days</th>
                  <th>Global factor 7 days</th>
                  <th>Local factor 7 days</th>
                  <th>Total factor 7 days</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="mtreeCompression in mtreeCompressions"
                >
                  <td>{{ mtreeCompression.mtree }}</td>
                  <td>{{ mtreeCompression.precomp_24h }}</td>
                  <td>{{ mtreeCompression.postcomp_24h }}</td>
                  <td>{{ mtreeCompression.gc_24h }}</td>
                  <td>{{ mtreeCompression.lc_24h }}</td>
                  <td>{{ mtreeCompression.dedupFactor_24h }}</td>
                  <td>{{ mtreeCompression.precomp_7d }}</td>
                  <td>{{ mtreeCompression.postcomp_7d }}</td>
                  <td>{{ mtreeCompression.gc_7d }}</td>
                  <td>{{ mtreeCompression.lc_7d }}</td>
                  <td>{{ mtreeCompression.dedupFactor_7d }}</td>
                </tr>

              </tbody>
            </table>
          </div>
        </b-card>
      </b-col>
      <b-col
        v-if="boostConnections.length > 0"
        cols="12"
        md="12"
      >
        <b-card :title="`Boost connections (${boostConnections.length})`">
          <div class="table-responsive config-table-wrapper">
            <table class="table table-sm table-borderless">
              <thead>
                <tr>
                  <th>Client</th>
                  <th>Idle</th>
                  <th>Plugin version</th>
                  <th>OS version</th>
                  <th>Application Version</th>
                  <th>Encrypted</th>
                  <th>DSP</th>
                  <th>Transport</th>
                  <th>Mem</th>
                  <th>CPUs</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="boostConnection in boostConnections"
                >
                  <td>{{ boostConnection.client }}</td>
                  <td>{{ boostConnection.idle }}</td>
                  <td>{{ boostConnection.boostVers }}</td>
                  <td>{{ boostConnection.os }}</td>
                  <td>{{ boostConnection.app }}</td>
                  <td>{{ boostConnection.encr }}</td>
                  <td>{{ boostConnection.dsp }}</td>
                  <td>{{ boostConnection.trans }}</td>
                  <td>{{ boostConnection.mem }}</td>
                  <td>{{ boostConnection.cpus }}</td>
                </tr>

              </tbody>
            </table>
          </div>
        </b-card>
      </b-col>
      <b-col
        v-if="enclousures.length > 0"
        cols="12"
        md="6"
      >
        <b-card :title="`Enclosures (${enclousures.length})`">
          <div class="table-responsive config-table-wrapper">
            <table class="table table-sm table-borderless">
              <thead>
                <tr>
                  <th>Model</th>
                  <th>Serial</th>
                  <th>State</th>
                  <th>Capacity</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="enclousure in enclousures"
                >
                  <td>{{ enclousure.modelNo }}</td>
                  <td>{{ enclousure.serialNo }}</td>
                  <td>{{ enclousure.state }}</td>
                  <td>{{ enclousure.capacity }}</td>
                </tr>

              </tbody>
            </table>
          </div>
        </b-card>
      </b-col>
      <b-col
        v-if="disks.length > 0"
        cols="12"
        md="6"
      >
        <b-card :title="`Disks (${disks.length})`">
          <div class="table-responsive config-table-wrapper">
            <table class="table table-sm table-borderless">
              <thead>
                <tr>
                  <th>Disk</th>
                  <th>Slot</th>
                  <th>Model</th>
                  <th>Serial</th>
                  <th>Capacity</th>
                  <th>Type</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="disk in disks"
                >
                  <td>{{ disk.diskDetail }}</td>
                  <td>{{ disk.slot }}</td>
                  <td>{{ disk.manufacturerModel }}</td>
                  <td>{{ disk.serialNo }}</td>
                  <td>{{ disk.capacity }}</td>
                  <td>{{ disk.type }}</td>
                </tr>

              </tbody>
            </table>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BCard } from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
  },
  props: {
    asup: {
      type: Object,
      default: () => {},
    },
    asset: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    licenseFeatures() {
      if (this.asup.details && this.asup.details.licenseFeatures) {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        return this.asup.details.licenseFeatures.sort((a, b) => (a.feature > b.feature ? 1 : -1))
      }

      return []
    },
    licenseCapacities() {
      if (this.asup.details && this.asup.details.licenseCapacities) {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        return this.asup.details.licenseCapacities.sort((a, b) => (a.feature > b.feature ? 1 : -1))
      }

      return []
    },
    enclousures() {
      if (this.asup.details && this.asup.details.enclousures) {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        return this.asup.details.enclousures.sort((a, b) => (a.enclosureName > b.enclosureName ? 1 : -1))
      }

      return []
    },
    disks() {
      if (this.asup.details && this.asup.details.disks) {
        // sort disks by diskDetail (e.g. "1.12, 2.5, ..")
        // We need to split the string into 2 parts and sort by first part, then by second part
        return this.asup.details.disks
          .concat()
          .map(x => {
            const splitPart = x.diskDetail.split('.')
            // eslint-disable-next-line no-param-reassign
            x.sort1 = Number.parseFloat(splitPart[0])
            // eslint-disable-next-line no-param-reassign
            x.sort2 = Number.parseFloat(splitPart[1])
            return x
          })
          .sort((a, b) => {
            if (a.sort1 > b.sort1) {
              return 1
            }
            if (a.sort1 < b.sort1) {
              return -1
            }

            return a.sort2 > b.sort2 ? 1 : -1
          })
      }

      return []
    },
    boostConnections() {
      if (this.asup.details && this.asup.details.boostConnections) {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        return this.asup.details.boostConnections.sort((a, b) => (a.client > b.client ? 1 : -1))
      }

      return []
    },
    mtreeEntries() {
      if (this.asup.details && this.asup.details.mtreeList) {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        return this.asup.details.mtreeList.sort((a, b) => (a.mtree > b.mtree ? 1 : -1))
      }

      return []
    },
    mtreeCompressions() {
      if (this.asup.details && this.asup.details.lastStatistic && this.asup.details.lastStatistic.mtreeCompressions) {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        return this.asup.details.lastStatistic.mtreeCompressions.sort((a, b) => (a.mtree > b.mtree ? 1 : -1))
      }

      return []
    },
  },
}
</script>

<style scoped>
  .config-table-wrapper {
    max-height: 400px;
    overflow-y: auto
  }
</style>
